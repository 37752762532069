@charset "UTF-8";

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$font-size: 14px !default;
$pad-font-size: 12px !default;
$font-line-height: 1.5 !default;
$font-family: San Francisco UI, Helvetica Neue, Arial, DIN Alternate, PingFang SC, Hiragino Sans GB, Micrisoft YaHei !default;

// color
$primary-color: var(--primary-color) !default; // 主色
$primary-color-light: #87abdc !default; // hover
$secondary-color: var(--secondary-color) !default; // 辅色 ｜ 链接
$contrast-color: var(--contrast-color) !default; // 对比色 | 成功
$warning-color: #f53f3f !default; //警示颜色 ｜ 失败
$default-text-color: #192d51 !default; // 表格文本颜色

$info-color: #598ae3 !default; // 蓝色
$tips-color: #ffa246 !default; //提示颜色
$link-color: #666 $primary-color !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$bg-color: #fff !default;
$bg-contentColor: #f6f7f9; //内容色
$bg-disableColor: #f1f2f6; //禁用色
$bg-icColor: #999fb6; //图标色
$line-color: #e4e8ee; //分割线
$stroke-color: #dadee5; //描边
$placeholder-color: #b3b0ba !default;
$white: white !default;
$black: black !default;
$colorPrimary: $primary-color; // for wtdc components
$colorPrimaryLight: #87abdc; // for wtdc components 指向色

// base values
$marginBase: 20px !default;
$paddingBase: 20px !default;
$paddingContent: 24px !default;

// text color
$text-color: #333333 !default;
$text-color-dark: #8f959e !default;
$text-color-light: #192d51 !default;
$text-color-gray: #9a9fb4 !default;
$tword-color: #192d51; //正文字体颜色

$text-color-8: rgba($text-color-dark, 0.8);
$text-color-6: rgba($text-color-dark, 0.6);
$text-color-2: rgba($text-color-dark, 0.2);

// border color
$border-color: #ddd !default;

//  border radius
$radiusSmall: 4px !default;
$radiusBase: 6px !default;

// bg color
$page-gray-bg: #f1f2f6 !default;

// 弹窗头部背景颜色
$popup-bg: #f4f6f9;
// form
$inputWidth: 256px !default;

// media
$tablet-width: 1024px;
$laptop-width: 1280px;
$desktop-width: 1920px;

// zIndex
$headerIndex: 100;
