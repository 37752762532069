@import "~@/assets/sass/_common.scss";
@import "~@/assets/sass/_animation.scss";
@import "~@/assets/sass/_base";

@import "~@/assets/sass/_antd.scss";

body {
  // 定义全局css 变量
  --minWidth: 1480px;
  --borderBase: 4px;
  --primary-color: #3875c6; // 主色
  --secondary-color: #3875c6; // 辅色
  --contrast-color: #04ba97; // 对比色
}

#App {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: var(--minWidth);
}
